@import "global";

.back_button {
  border: 1px solid black;
  color: black;

  &:hover {
    background-color: gray;
    color: white;
  }
}

.readMoreButton {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 550;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  max-width: 300px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(90deg, $cream, $braun);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 10px 20px;
    // font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    // font-size: 0.9rem;
    padding: 8px 16px;
  }
}

.readMoreButton:hover {
  background: linear-gradient(90deg, $braun, $cream);
  transform: scale(1.05);
}

.readMoreButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 120%,
    rgb(203, 203, 203),
    transparent 70%
  );
  opacity: 0.8;
  transform-origin: 50% 100%;
  transform: scaleY(0);
  transition: transform 0.3s ease-in-out;
}

.readMoreButton:hover::before {
  transform: scaleY(1);
}

@media (max-width: 870px) {
  .back_button {
    display: none;
  }
}
