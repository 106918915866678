@import "global";

.section {
  padding: 80px 20px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1000px) {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .row {
        flex-direction: column;
      }

      .col-md-6 {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        ul {
          text-align: start;
        }

        &:last-child {
          margin-bottom: 0;
        }

        img {
          height: auto;
        }
      }
    }
  }
}

.section-energy {
  @include backgroundImg((url("../imgs/hands.png") center/cover no-repeat));
  max-height: 700px;

  @media (max-width: 660px) {
    height: 70vh;
    padding: 0 20px;
  }

  @media (max-width: 390px) {
    p {
      font-size: 14px;
    }
  }

  .center {
    @media (max-width: 770px) {
      justify-content: flex-start;
      padding-top: 80px;
      height: 60vh;
    }

    @media (max-width: 500px) {
      justify-content: flex-start;
      padding-top: 40px;
    }
  }
}

.section-quote {
  @include backgroundImg((url("../imgs/crystals.png") center/cover no-repeat));
}

.section h2 {
  margin-bottom: 20px;
}

.section .h2-2 {
  font-size: 26px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.section ul li {
  color: #4a4a4a;
}

.section img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
}

.section {
  .symbol {
    @include symbol;
    top: 0;
    left: 50%;

    @media (max-width: 1000px) {
      margin-top: 0;
      max-width: 80vw;
    }
  }

  .symbol-left {
    @include symbol;
    margin-top: 0;
    left: 0;
    transform: rotate(-90deg);

    @media (max-width: 1000px) {
      margin-top: 0;
      max-width: 100vw;
    }
  }

  .symbol-right {
    @include symbol;
    right: 0;
    transform: rotate(90deg);

    @media (max-width: 1000px) {
      max-width: 100vw;
    }
  }
}

.content {
  position: relative;
  z-index: 2;
  height: 100%;
}

.more-services {
  // Background images (crystals)
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }

  &::before {
    bottom: 0%;
    left: 0%;
    width: 400px;
    height: 400px;
    background-image: url("../imgs/roseCrystal.png");
  }

  &::after {
    top: 0%;
    right: 0%;
    width: 400px;
    height: 400px;
    background-image: url("../imgs/roseCrystal.png");
    transform: rotate(180deg);

    @media (max-width: 880px) {
      display: none;
    }
  }
}

@media (max-width: 1000px) {
  .order-md-1 {
    order: 2 !important;
  }

  .order-md-2 {
    order: 1 !important;
  }
}

.impressum {
  padding: 50px;
}

.impressum-content {
  width: 70%;
  background-color: white;
  padding: 50px;
}

.datenschutz {
  padding: 50px;

  h1 {
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 25px;
    font-weight: bold;
  }

  h2 {
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 22px;
    font-weight: 700;
  }

  h3 {
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }

  h4 {
    padding: 10px 0;
    font-size: 20px;
  }
}

@media (max-width: 870px) {
  .impressum {
    padding: 0px;
  }

  .impressum-content {
    width: 95%;
    padding: 20px;

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .datenschutz {
    padding: 0px;
  }
}

// .calendly-container {
// height: 80vh;
// margin: 0 auto;
// padding: 20px;
// background-color: #f8f9fa;
// border-radius: 10px;
// box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// }

// @media (max-width: 768px) {
//     .calendly-container {
//         padding: 10px;
//     }
// }

// @media (max-width: 768px) {
//     .section {
//         padding: 20px 10px;
//     }
// }
